import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    tap_appbar_value: "/dashboard",
    list_count_series: [],
    list_xaxis_categories: [],

    pie_obj_data: [
      {
        series: [44, 55, 13, 43],
        chartOptions: {
          chart: {
            width: 320,
            type: "pie",
          },
          title: {
            text: "Pie title",
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          labels: ["label 1", "label 2", "label 3", "label 4"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          colors: ["#495896", "#F8A03A", "#ACB9FF", "#FFACAC", "#D1ACFF"],
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex];
              return [name, val.toFixed(1) + "%"];
            },
          },
          legend: {
            show: false,
          },
        },
      },
      {
        series: [44, 55, 13, 43],
        chartOptions: {
          chart: {
            width: 320,
            type: "pie",
          },
          title: {
            text: "Pie title",
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          labels: ["label 1", "label 2", "label 3", "label 4"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          colors: ["#495896", "#F8A03A", "#ACB9FF", "#FFACAC", "#D1ACFF"],
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex];
              return [name, val.toFixed(1) + "%"];
            },
          },
          legend: {
            show: false,
          },
        },
      },
      {
        series: [44, 55, 13, 43],
        chartOptions: {
          chart: {
            width: 320,
            type: "pie",
          },
          title: {
            text: "Pie title",
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          labels: ["label 1", "label 2", "label 3", "label 4"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          colors: ["#495896", "#F8A03A", "#ACB9FF", "#FFACAC", "#D1ACFF"],
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex];
              return [name, val.toFixed(1) + "%"];
            },
          },
          legend: {
            show: false,
          },
        },
      },
      {
        series: [44, 55, 13, 43],
        chartOptions: {
          chart: {
            width: 320,
            type: "pie",
          },
          title: {
            text: "Pie title",
          },
          plotOptions: {
            pie: {
              dataLabels: {
                offset: -5,
              },
            },
          },
          labels: ["label 1", "label 2", "label 3", "label 4"],
          responsive: [
            {
              breakpoint: 480,
              options: {
                chart: {
                  width: 200,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
          ],
          colors: ["#495896", "#F8A03A", "#ACB9FF", "#FFACAC", "#D1ACFF"],
          dataLabels: {
            formatter(val, opts) {
              const name = opts.w.globals.labels[opts.seriesIndex];
              return [name, val.toFixed(1) + "%"];
            },
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  },
  mutations: {
    mu_tap_appbar_value(state, n) {
      state.tap_appbar_value = n
    },
    data_total_list_count_series(state, n) {
      state.list_count_series = n
    },
    data_total_list_xaxis_categories(state, n) {
      state.list_xaxis_categories = n
    },
    data_pie_obj_data(state, n) {
      state.pie_obj_data = n
    },
  },
  actions: {
  },
  modules: {
  }
})
