import Vue from "vue";
import VueRouter from "vue-router";

//views
const Login = () => import("../views/Login/Login.vue");

//components
const HomePage = () => import("../components/Homepage/Homepage.vue");
const LoginFail = () => import("../components/LoginFail/LoginFail.vue");
const Thankyou = () => import("../components/Thankyou/Thankyou.vue");

Vue.use(VueRouter);

const routes = [
  {
    path: "/login/:ticket_id",
    name: "Login",
    component: Login,
  },
  {
    path: "/",
    name: "Login",
    component: Login,
  },
  {
    path: "/homePage/:ticket_id",
    name: "HomePage",
    component: HomePage,
  },
  {
    path: "/thankyou",
    name: "Thankyou",
    component: Thankyou,
  },
  {
    path: "/loginFail",
    name: "LoginFail",
    component: LoginFail,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
});
// router.beforeEach((to, from, next) => {
//   console.log(to.path);
// })

export default router;
